// Feature flags start
export const INSIGHTS_COLOR_SCHEME_V2 = 'insights_color_scheme_v2';
export const INSIGHTS_DISTRIBUTOR_TOGGLE = 'insights_distributor_toggle';
export const INSIGHTS_SHOW_SME_DATA = 'show_sme_data';
export const INSIGHTS_ES_SEARCH_NEXT = 'insights_es_search_next';
export const INSIGHTS_POT_ZOOMABLE = 'insights_pot_zoomable';
export const INSIGHTS_POT_HEADER_V2 = 'insights_pot_header_v2';
export const INSIGHTS_SONG_POT_IA = 'insights_song_pot_ia';
export const INSIGHTS_ACCOUNT_POT_IA = 'insights_account_pot_ia';
export const INSIGHTS_SONG_POT_FOOTER = 'insights_song_pot_footer';
export const INSIGHTS_ACCOUNT_POT_FOOTER = 'insights_account_pot_footer';
export const INSIGHTS_YOUTUBE_POT_FOOTER = 'insights_youtube_pot_footer';
export const INSIGHTS_GAINERS_EMPLOYEE_FILTERS =
    'insights_gainers_employee_filters';
export const INSIGHTS_GAINERS_LABEL_FILTER = 'insights_gainers_label_filter';
export const INSIGHTS_INTERCOM = 'insights_intercom';
export const INSIGHTS_SOURCES_TABLE_POPUP_V2 =
    'insights_sources_table_popup_v2';
export const INSIGHTS_PLAYLISTS_STORE_GRAPHS =
    'insights_playlists_store_graphs';
export const INSIGHTS_SONG_COMPARISON = 'insights_song_comparison';
export const INSIGHTS_VIDEO_COMPARISON = 'insights_video_comparison';
export const INSIGHTS_UGC_STATUS = 'insights_ugc_status';
export const INSIGHTS_UGC_PERCENTAGE = 'insights_ugc_percentage';
export const INSIGHTS_VIDEO_CATALOG_ARTIST = 'insights_video_catalog_artist';
export const INSIGHTS_ACCOUNT_YOUTUBE_STREAMS =
    'insights_account_youtube_streams';
export const INSIGHTS_YOUTUBE_REVENUE = 'insights_youtube_revenue';
export const INSIGHTS_MINIPLAYER_PLAYLISTS = 'insights_miniplayer_playlists';
export const INSIGHTS_TIKTOK_TREND_SCORE_COLUMN =
    'insights_tiktok_trend_score_column';
export const INSIGHTS_EMPLOYEE_OVERRIDE = 'insights_employee_override';
export const INSIGHTS_SME_EMPLOYEE = 'insights_sme_employee';
export const INSIGHTS_MSK_AB_COMPANY_BRANDS = 'insights_msk_ab_company_brands';
export const INSIGHTS_AWAL_VIDEOS = 'insights_awal_videos';
export const INSIGHTS_SMART_PRODUCTS_FOR_CLIENTS =
    'insights_smart_products_for_clients';
export const INSIGHTS_ARTIST_PLAYLIST_SONGFILTER =
    'insights_artist_playlist_songfilter';
export const INSIGHTS_MARKETS_SELECTOR = 'insights_market_selector';
export const INSIGHTS_ORCHARD_GO_BANNER = 'insights_orchard_go_banner';
export const INSIGHTS_SEARCH_UPDATES = 'insights_search_updates';
export const INSIGHTS_MIGRATE_SOCIAL_METRICS =
    'insights_migrate_social_metrics_component_song_page';
export const INSIGHTS_SONG_TABLE_UPDATES = 'insights_update_song_table';
export const INSIGHTS_FIN_LABEL_MODE = 'insights_fin_label_rca';
export const INSIGHTS_PROFIT_CENTER = 'insights_profit_center';
export const INSIGHTS_CHARTS_BRANDING_V2 = 'insights_charts_branding_v2';
export const INSIGHTS_YT_CHARTS_EXPORT = 'insights_youtube_charts_export';
export const INSIGHTS_SONG_PLAYLISTS_REDESIGN =
    'insights_song_playlists_redesign';
export const INSIGHTS_TIKTOK_SONGS_PAGE = 'insights_tiktok_songs_page';
export const INSIGHTS_SEARCH_V2_BRAND_FILTER =
    'insights_search_v2_brand_filter';
export const INSIGHTS_SOCIAL_LINKS_EDITING = 'insights_social_links_editing';
export const INSIGHTS_PRODUCT_PAGE_PLAYLISTS_TAB =
    'insights_product_page_playlists_tab';
export const INSIGHTS_NAVBAR_V2 = 'insights_navbar_v2';
// Feature flags end
